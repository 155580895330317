import React from 'react';
import './Footer.scss';
import { SiGooglemaps } from "react-icons/si";
import { FaPhone } from "react-icons/fa6";
import { CiMail } from "react-icons/ci";
const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer-container">
                <div className="footer-left">
                    <img src="logo.webp" alt="Halal Meat Quality" className="footer-logo" width="120" height="110" loading="lazy" />
                    <address className="footer-address">
                        <strong>Halal Meat Quality</strong><br />
                        <br/>
                        <SiGooglemaps />   3 Plaza pintor Dubon bajo 46015 valencia<br />
                        <br/>
                        <FaPhone />  +34623735698 <br />
                        <br/>
                        <a href="mailto:halalmeatquality@gmail.com"><CiMail /> halalmeatquality@gmail.com</a><br />
                      
                    </address>
                </div>
                <div className="footer-right">
                    <h3>Siège</h3>
                    <div className="footer-map">
                        <iframe 
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3078.7343955863503!2d-0.4044715047267049!3d39.
                            497911698380626!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd604591bda12805%3A0xcbc292e704079dfa!
                            2sPla%C3%A7a%20Pintor%20Dub%C3%B3n%2C%20Benicalap%2C%2046015%20Val%C3%A8ncia%2C%20Espagne!5e0!3m2!1sfr!
                            2sfr!4v1721730031181!5m2!1sfr!2sfr"
                            width="300" 
                            height="200" 
                            style={{ border: 0 }} 
                            allowFullScreen="" 
                            loading="lazy" 
                            title="Google Maps"
                        ></iframe>
                    </div>
                </div>
            </div>
            <div className="footer-bottom">
                <p>Copyright © 2024 Tous droits réservés</p>
            </div>
        </footer>
    );
};

export default Footer;
